<template>
  <Toast />
  <OrganizationForm
    :originalOrganization="organization"
    @onSubmit="handleSubmit"
    @uploadLogo="uploadLogo"
    v-if="!loading"
  />
  <ProgressSpinner v-else />
  <div v-if="!loading" class="text-right">
    <Button
      id="toggle-organization-btn"
      :class="enabled ? 'p-button-danger' : 'p-button-success'"
      @click="showEnablePopup($event)"
      >{{ enabled ? 'Desactivar' : 'Activar' }} punto de venta</Button
    >
    <p class="p-error" v-if="toggleError">{{ toggleError }}</p>
    <ConfirmPopup>
      <template #message="slotProps">
        <div class="flex p-4">
          <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
          <p class="pl-2">{{ slotProps.message.message }}</p>
        </div>
      </template>
    </ConfirmPopup>
  </div>
</template>

<script>
import OrganizationForm from '../../components/OrganizationForm.vue';
import OrganizationService from '../../service/OrganizationService';
import api from '../../api/Acutame';

const parseOrganizationConfig = (organization) => ({
  name: organization.name,
  smsName: organization.smsName,
  phone: organization.phone + '',
  dialCode: organization.dialCode,
  timezone: organization.timezone,
  email: organization.email,
  logoLocation: organization.logoLocation,
  address: {
    addressLine1: organization.address.addressLine1,
    addressLine2: organization.address.addressLine2,
    population: organization.address.population,
    province: organization.address.province,
    country: organization.address.country,
    zip: organization.address.zip,
  },
});

export default {
  created() {
    this.organizationService = new OrganizationService();
  },
  mounted() {
    this.organizationService
      .getOrganization(this.$route.params.id)
      .then((organization) => {
        const organizationConfig = parseOrganizationConfig(organization);
        this.enabled = !organization.disabled;
        this.organization = organizationConfig;
        this.logoString = organization.logoLocation;
      });
    this.loading = false;
  },
  components: {
    OrganizationForm,
  },
  data() {
    return {
      organization: null,
      enabled: null,
      loading: true,
      toggleError: null,
    };
  },
  methods: {
    showEnablePopup(event) {
      this.$confirm.require({
        target: event.currentTarget,
        acceptClass: this.enabled ? 'p-button-danger' : 'p-button-success',
        rejectLabel: 'Cancelar',
        acceptLabel: this.enabled ? 'Desactivar' : 'Activar',
        message: this.enabled
          ? 'Está a punto de desactivar el punto de venta'
          : 'Desea activar el punto de venta',
        accept: () => {
          this.toggleOrganization();
        },
      });
    },
    async toggleOrganization() {
      this.loading = true;
      let org;
      try {
        org = await api.changeOrganizationEnabledState(
          this.$route.params.id,
          !this.enabled
        );
      } catch (error) {
        const errorJson = await error.response.json();
        console.error(errorJson);
        this.toggleError =
          errorJson?.message ===
          'No organization slots left. Please upgrade your plan'
            ? 'Ya tiene el máximo de puntos de venta activados para las condiciones de su plan actual. Tendría que desactivar alguno antes para poder activar uno nuevo.'
            : 'Error activando la organización. Por favor inténtelo más tarde.';
        this.loading = false;
        return;
      }
      this.organization = parseOrganizationConfig(org);
      this.enabled = !org.disabled;
      this.loading = false;
      this.$store.dispatch('initialize');
    },
    async uploadLogo(logo, cleanup) {
      try {
        const logoString = await this.organizationService.uploadLogo(
          this.$route.params.id,
          logo
        );
        this.logoString = logoString;
        this.$toast.add({
          severity: 'info',
          summary: 'Logo subido con éxito',
          life: 3000,
        });
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Ha habido un error subiendo el logo. Inténtelo más tarde',
          life: 3000,
        });
      }
      cleanup();
    },
    async handleSubmit(organizationSettings) {
      try {
        const organization =
          await this.organizationService.putOrganizationConfig(
            this.$route.params.id,
            organizationSettings
          );
        const organizationConfig = parseOrganizationConfig(organization);
        this.organization = organizationConfig;
        this.$toast.add({
          severity: 'info',
          summary: 'Configuración guardada con éxito',
          life: 3000,
        });
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail:
            'Ha habido un error actualizando la configuración. Inténtelo más tarde',
          life: 3000,
        });
      }
    },
  },
};
</script>

<style></style>
